import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';

import client from '@/apollo-client';

import '@/styles/globals.sass';

function CakeMagApp({ Component, pageProps }) {
  const { asPath } = useRouter();
  const basePath = 'https://www.cake-mag.com';
  const canonicalUrl = `${basePath}${asPath}`;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          key="description"
          name="description"
          content="the indie, confused and sweet magazine"
        />
        <meta
          key="ogDescription"
          property="og:description"
          content="the indie, confused and sweet magazine"
        />
        <meta property="og:url" content={canonicalUrl} />
        <meta
          key="ogImage"
          property="og:image"
          content="/apple-touch-icon.png"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <ApolloProvider client={client}>
        <Component {...pageProps} />
      </ApolloProvider>
      <Script
        src="https://cdn.websitepolicies.io/lib/cookieconsent/cookieconsent.min.js"
        defer
      />
      <Script id="cookie">
        {`
        window.addEventListener("load",function()
        {
          if (!window.wpcc) { return; }
          window.wpcc.init({
          colors: {
            popup: {
              background: '#f6f6f6',
              text: '#000000',
              border: '#555555',
            },
            button: { background: '#555555', text: '#ffffff' },
          },
          corners: 'large',
          padding: 'large',
          margin: 'large',
          fontsize: 'large',
          transparency: 10,
          content: {
            href: '/cookie-policy',
            message:
              'We serve cookies on this site to analyze traffic, remember your preferences, and optimize your experience.',
          },
        })}
        );
      `}
      </Script>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-B9EH1ERNCM"
        strategy="lazyOnload"
      />
      <Script id="ga">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'G-B9EH1ERNCM');`}
      </Script>
    </>
  );
}

export default CakeMagApp;

CakeMagApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
};
