import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      RootQueryToPostConnection: {
        fields: {
          nodes: {
            keyArgs: [],
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
  uri: process.env.NEXT_PUBLIC_WORDPRESS_API_URL,
});

export default client;
